@font-face {
	font-family: "LinLibertine_Regular";
	src: url("../assets/UI/FONT/linlibertine_r_g-webfont.woff2") format('woff2'),
		 url("../assets/UI/FONT/linlibertine_r_g-webfont.woff") format('woff'),
		 url("../assets/UI/FONT/LinLibertine_R_G.ttf") format('ttf');
	font-display: block;
}

/* ----------- Resetters ----------- */

* {
	margin: 0;
	padding: 0;
}

body {
	--app-height: 100vh;
	overflow: hidden;
	position: relative;
	background-color: black;
}

a {
	color: unset;
	text-decoration: unset;
}

h1 {
	font-size: max(1.8vw, 1.8vh);
}

h2 {
	font-size: max(1.1vw, 1.1vh);
}

h3 {
	font-size: max(1.1vw, 1.1vh);
}

h5 {
	font-size: max(0.9vw, 0.9vh);
}

/* ----------- Reusable component classes ----------- */

.container {
	position: absolute;
	font-family: "LinLibertine_Regular";
	font-smooth: never;
	text-transform: uppercase;
	transition: all 300ms ease-out;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: var(--app-height);
	text-align: center;
}

.is-hidden {
	opacity: 0;
}

.is-gone {
	display: none;
}

.space-above {
	padding-top: 20px;
}

.pointer {
	cursor: pointer;
}

.cursor-hidden {
	cursor: none;
}

.heading-text {
	color: transparent;
	background-color: #c9c9c9;
	background-clip: text;
	-webkit-background-clip: text;
	text-shadow: 0px 2px 1px #25251b, 0 1px 6px #00ff33, 0 1px 8px #00ff33;
}

.metallic-children > *,
.metallic-text {
	text-transform: uppercase;
	background: linear-gradient(rgb(255, 255, 255) 49%, rgb(161, 200, 161) 51%);
	line-height: 1em;
	background-size: 1em 1em;
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}

/* ----------- Specific elements ----------- */

#main-landing {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: max(39vw, 39vh);
	width: max(41vw, 41vh);
}

.content-subheader {
	margin-top: max(0.7vw, 0.7vh);
}

#about,
#story {
	display: none;

	&.is-visible {
		display: flex;
	}

	& > #about-content,
	& > #story-content {
		box-sizing: border-box;
		padding: 100px 0 30px;
		background: url("../assets/UI/border.png") black;
		background-size: 100% 100%;
		width: 472px;
		height: 687px;
		max-width: 100%;
		max-height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
	}

	& h1 {
		margin-bottom: 20px;
	}

	& span {
		display: block;
		margin-bottom: 5px;
	}

	& p {
		text-transform: initial;
		margin-bottom: 1em;
	}

	& .content-story-body,
	& .about-body {
		overflow: auto;
		padding: 0 30px;

		&::-webkit-scrollbar-track {
			background-color: red;
			border-right: 2px solid green;
		}

		&::-webkit-scrollbar {
			width: 10px;
			background-color: red;
			border-right: 2px solid green;
			margin-right: 20px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: white;
			border-right: 2px solid green;
		}
	}
}

#autoplay-button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	width: max(5vw, 5vh);
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	& #autoplay-button-mark.is-hidden {
		display: none;
	}

	& #autoplay-button-empty.is-hidden {
		display: none;
	}

	& img {
		width: 50%;
	}
}

#subtitle-button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	width: max(5vw, 5vh);
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	& #subtitle-button-mark.is-hidden {
		display: none;
	}

	& #subtitle-button-empty.is-hidden {
		display: none;
	}

	& img {
		width: 50%;
	}
}

#subtitle {
	position: absolute;
	bottom: 10vh;
	text-align: center;
	font-family: sans-serif;
	width: 70vw;
	left: 15vw;
	font-size: max(2vw, 2vh);
	color: yellow;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
		1px 1px 0 #000, 0 4px 0 black;
}

.loadbar-container {
	width: 20%;
	height: 10px;
	background-color: #00ff33;
	border: 1px solid grey;
	margin-top: 5px;
	position: relative;
}

#loadbar-indicator {
	position: absolute;
	top: 1px;
	left: 1px;
	background-color: #000;
	width: 1%;
	height: 8px;
}

#loadbar-filename {
	margin-top: 5px;
	font-size: 10px;
	text-shadow: none;
	color: green;
}

@media (max-width: 765px) {
	#main-landing {
		width: 100vw;
		height: calc(0.575 * var(--app-height));
	}

	#autoplay-button,
	#subtitle-button {
		width: 15vw;
		top: 115%;
	}

	#autoplay-button {
		left: 18vw;
	}

	#subtitle-button {
		right: 18vw;
	}

	h1 {
		font-size: 28px;
	}

	h2 {
		font-size: 20px;
	}

	h3 {
		font-size: 20px;
	}

	h5 {
		font-size: 16px;
	}
}
